import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Home,
  Info,
  Assessment,
  ExitToApp,
  Menu,
  ArrowBack,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { useUser } from "../UserContext"; // Importe o hook useUser

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Montserrat, sans-serif", // Font family aplicada a todo o Dashboard
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#09a1b7", // Azul da barra de aplicação
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Montserrat, sans-serif", // Font family aplicada ao conteúdo
  },
  toolbar: theme.mixins.toolbar,
  listItemText: {
    fontFamily: "Montserrat, sans-serif", // Font family aplicada aos itens da lista
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  welcomeText: {
    marginBottom: theme.spacing(2),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { role, isAuthenticated, logout } = useUser(); // Use o hook useUser para obter o role e o estado de autenticação
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleBack = () => {
    setOpenDrawer(false); // Fecha o Drawer ao voltar
  };

  // Fecha o Drawer ao mudar de rota e oculta o texto de boas-vindas
  useEffect(() => {
    setOpenDrawer(false);
    setShowWelcome(location.pathname === "/"); // Exibe a mensagem apenas na página inicial
  }, [location]);

  if (!isAuthenticated) {
    return <div>Você precisa estar logado para acessar o dashboard.</div>;
  }

  // Determina o texto de boas-vindas com base no role
  const roleText =
    {
      adm: "Administrador",
      operador: "Operador",
      diretor: "Diretor",
    }[role] || "Usuário";

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Menu
            onClick={toggleDrawer}
            className={classes.menuButton}
            color="inherit"
          />
          <Typography variant="h6" noWrap>
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          <ListItem button onClick={handleBack}>
            <ListItemIcon>
              <ArrowBack />
            </ListItemIcon>
            <ListItemText primary="Voltar" />
          </ListItem>
          <ListItem button component={Link} to="/cadastros">
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText
              primary="Cadastros"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          {/* Adicione outros itens de menu aqui */}
        </List>

        <List>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText
              primary="Sair"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {showWelcome && (
          <Typography variant="h6" className={classes.welcomeText}>
            Bem-vindo, {roleText}!
          </Typography>
        )}
        <Outlet />
      </main>
    </div>
  );
};

export default Dashboard;
