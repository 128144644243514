import React from "react";
import {
  TextField,
  Grid,
  Container,
  Button,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { Divider } from "@material-ui/core";
import InputMask from "react-input-mask";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

function FormularioDiretoria({ initialValues, handleClose, onSubmit }) {
  const classes = useStyles();

  const { handleSubmit, control, formState } = useForm({
    defaultValues: initialValues,
  });

  const { isValid, dirtyFields, errors } = formState;

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Controller
              name="OPERACAO"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Operação"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="SUB"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Sub"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="CODIGO"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Código"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="COD"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Cod"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              name="DTADMISSAO"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputMask
                  mask="99/99/9999"
                  value={field.value}
                  onChange={field.onChange}
                >
                  {() => (
                    <TextField
                      className={classes.textField}
                      label="Data Admissão"
                      fullWidth
                      variant="outlined"
                      placeholder="dd/mm/aaaa"
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              name="DTVIGENCIA"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputMask
                  mask="99/99/9999"
                  value={field.value}
                  onChange={field.onChange}
                >
                  {() => (
                    <TextField
                      className={classes.textField}
                      label="Data Vigência"
                      fullWidth
                      variant="outlined"
                      placeholder="dd/mm/aaaa"
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              name="DATCANCELA"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputMask
                  mask="99/99/9999"
                  value={field.value}
                  onChange={field.onChange}
                >
                  {() => (
                    <TextField
                      className={classes.textField}
                      label="Data Cancelamento"
                      fullWidth
                      variant="outlined"
                      placeholder="dd/mm/aaaa"
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="CODPLANO"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Cod Plano"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="CODRE"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Cod RE"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="DVRE"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="DV RE"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="CODSPREV"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Cod Sprev"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="CODCOO"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Cod COO"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="DATADM"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputMask
                  mask="99/99/9999"
                  value={field.value}
                  onChange={field.onChange}
                >
                  {() => (
                    <TextField
                      className={classes.textField}
                      label="Data ADM"
                      fullWidth
                      variant="outlined"
                      placeholder="dd/mm/aaaa"
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="VENCTO"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputMask
                  mask="99/99/9999"
                  value={field.value}
                  onChange={field.onChange}
                >
                  {() => (
                    <TextField
                      className={classes.textField}
                      label="Vencimento"
                      fullWidth
                      variant="outlined"
                      placeholder="dd/mm/aaaa"
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="NOMEPREP"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Nome Prep"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="CPFPREP"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="CPF Prep"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="VLRMENSAL"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Valor Mensal"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="CORRECFAIX"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Correção Faixa"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="MESREAJUST"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Mês Reajuste"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="EQUIPE"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Equipe"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="VENDEDOR"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Vendedor"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="TABCARENCI"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Tabela Carência"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="APOSENTADO"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Aposentado"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="CCO"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="CCO"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="MATANT"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Mat Ant"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="OPERADORA"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Operadora"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Controller
              name="GRUPOEMP"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Grupo Emp"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="CONTRATO"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Contrato"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="VERCON"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Ver Con"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="SUBCONT"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Sub Cont"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              name="VERSUB"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textField}
                  label="Ver Sub"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button color="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            //disabled={_.isEmpty(dirtyFields) || !isValid}
          >
            {initialValues ? "Salvar" : "Cadastrar"}
          </Button>
        </DialogActions>
      </form>
    </Container>
  );
}

export default FormularioDiretoria;
