import React, { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Button,
  useTheme,
} from "@mui/material";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import FormularioDiretoria from "./FormularioDiretoria";
import TabelaCadastrosDiretoria from "./TabelaCadastrosDiretoria";
import CadastrosSidebarContent from "./CadastrosSidebarContent";

// Mock data
const initialCadastros = [
  {
    id: 1,
    GRUPOEMP: "Empresa A",
    CODPLANO: "PL001",
    DTVIGENCIA: "01/01/2024",
  },
  {
    id: 2,
    GRUPOEMP: "Empresa B",
    CODPLANO: "PL002",
    DTVIGENCIA: "15/02/2024",
  },
  {
    id: 3,
    GRUPOEMP: "Empresa C",
    CODPLANO: "PL003",
    DTVIGENCIA: "30/03/2024",
  },
  {
    id: 4,
    GRUPOEMP: "Empresa D",
    CODPLANO: "PL004",
    DTVIGENCIA: "01/04/2024",
  },
  {
    id: 5,
    GRUPOEMP: "Empresa E",
    CODPLANO: "PL005",
    DTVIGENCIA: "15/05/2024",
  },
];

const Sidebar = styled("div")(({ theme }) => ({
  minWidth: 250,
  flexShrink: 0,
  marginRight: theme.spacing(1),
}));

const CadastroDiretoria = () => {
  const [open, setOpen] = useState(false);
  const [cadastroSelecionado, setCadastroSelecionado] = useState(null);
  const [cadastros, setCadastros] = useState(initialCadastros);

  const theme = createTheme({
    typography: {
      fontFamily: "Montserrat, sans-serif",
    },
    palette: {
      primary: {
        main: "#1686c6",
      },
      secondary: {
        main: "#05bcbc",
      },
    },
    spacing: 8,
  });

  const handleOpen = (cadastro) => {
    setCadastroSelecionado(cadastro);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCadastroSelecionado(null);
  };

  const handleCadastroSubmit = (data) => {
    if (cadastroSelecionado) {
      // Editar cadastro existente
      const updatedCadastros = cadastros.map((cadastro) =>
        cadastro.id === cadastroSelecionado.id
          ? { ...cadastro, ...data }
          : cadastro
      );
      setCadastros(updatedCadastros);
    } else {
      // Adicionar novo cadastro
      const newCadastro = { id: cadastros.length + 1, ...data };
      setCadastros([...cadastros, newCadastro]);
    }
    handleClose();
  };

  const handleEditarCadastro = (cadastro) => {
    setCadastroSelecionado(cadastro);
    setOpen(true);
  };

  const handleExcluirCadastro = (id) => {
    const updatedCadastros = cadastros.filter((cadastro) => cadastro.id !== id);
    setCadastros(updatedCadastros);
  };

  const role = "adm";

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex" }}>
        <Sidebar>
          <CadastrosSidebarContent onAddClick={() => handleOpen(null)} />
        </Sidebar>
        <main style={{ flex: 1, padding: theme.spacing(2) }}>
          <Typography variant="h4" gutterBottom>
            Planos de Saúde
          </Typography>
          <TabelaCadastrosDiretoria
            cadastros={cadastros}
            onEditar={handleEditarCadastro}
            onExcluir={handleExcluirCadastro}
            role={role}
          />
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>
              {cadastroSelecionado
                ? "Editar Plano de Saúde"
                : "Novo Plano de Saúde"}
            </DialogTitle>
            <DialogContent>
              <FormularioDiretoria
                initialValues={cadastroSelecionado}
                onSubmit={handleCadastroSubmit}
                handleClose={handleClose}
              />
            </DialogContent>
            <Divider />
          </Dialog>
        </main>
      </div>
    </ThemeProvider>
  );
};

export default CadastroDiretoria;
