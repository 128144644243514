import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  styled,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import TabelaCadastros from "./TabelaCadastros";
import FormularioCadastro from "./FormularioCadastro";
import { useForm } from "react-hook-form";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  palette: {
    primary: {
      main: "#1686c6",
    },
    secondary: {
      main: "#05bcbc",
    },
  },
});

const Root = styled("div")(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "auto",
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  padding: theme.spacing(2),
  boxSizing: "border-box",
  flexGrow: 1,
}));

const MainContent = styled("div")(({ theme }) => ({
  flexGrow: 1,
  margin: "1rem 0 0 1rem",
}));

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  justifyContent: "space-between",
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: "#05bcbc",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#74d9d9",
  },
}));

const VisualizarCadastro = () => {
  const role = "operador";
  const { handleSubmit, control, formState } = useForm();
  const { isValid, dirtyFields, errors } = formState;
  const [open, setOpen] = useState(false);
  const [cadastroSelecionado, setCadastroSelecionado] = useState(null);
  const [cadastros, setCadastros] = useState([
    {
      id: 1,
      nome: "João Silva",
      email: "joao.silva@example.com",
      cidade: "São Paulo",
    },
    {
      id: 2,
      nome: "Maria Santos",
      email: "maria.santos@example.com",
      cidade: "Rio de Janeiro",
    },
    {
      id: 3,
      nome: "Pedro Oliveira",
      email: "pedro.oliveira@example.com",
      cidade: "Belo Horizonte",
    },
    {
      id: 4,
      nome: "Ana Souza",
      email: "ana.souza@example.com",
      cidade: "Salvador",
    },
    {
      id: 5,
      nome: "Luiz Pereira",
      email: "luiz.pereira@example.com",
      cidade: "Porto Alegre",
    },
    {
      id: 6,
      nome: "Carla Costa",
      email: "carla.costa@example.com",
      cidade: "Curitiba",
    },
    {
      id: 7,
      nome: "Rafaela Martins",
      email: "rafaela.martins@example.com",
      cidade: "Fortaleza",
    },
    {
      id: 8,
      nome: "Marcos Ferreira",
      email: "marcos.ferreira@example.com",
      cidade: "Recife",
    },
    {
      id: 9,
      nome: "Fernanda Lima",
      email: "fernanda.lima@example.com",
      cidade: "Brasília",
    },
    {
      id: 10,
      nome: "Gabriel Almeida",
      email: "gabriel.almeida@example.com",
      cidade: "Manaus",
    },
  ]);
  const [initialValues, setInitialValues] = useState(null);

  const handleOpen = (cadastro) => {
    setCadastroSelecionado(cadastro);
    setInitialValues(cadastro);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCadastroSelecionado(null);
    setInitialValues(null);
  };

  const handleCadastroSubmit = (data) => {
    if (cadastroSelecionado) {
      const updatedCadastros = cadastros.map((cadastro) =>
        cadastro.id === cadastroSelecionado.id
          ? { ...cadastro, ...data }
          : cadastro
      );
      setCadastros(updatedCadastros);
    } else {
      const newCadastro = { id: cadastros.length + 1, ...data };
      setCadastros([...cadastros, newCadastro]);
    }
    handleClose();
  };

  const handleEditarCadastro = (cadastro) => {
    setCadastroSelecionado(cadastro);
    setInitialValues(cadastro);
    setOpen(true);
  };

  const handleExcluirCadastro = (id) => {
    const updatedCadastros = cadastros.filter((cadastro) => cadastro.id !== id);
    setCadastros(updatedCadastros);
  };

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <ContentWrapper>
          <MainContent>
            <Typography variant="h4" gutterBottom>
              Cadastros
            </Typography>

            <TabelaCadastros
              cadastros={cadastros}
              onEditar={handleEditarCadastro}
              onExcluir={handleExcluirCadastro}
              role={role}
            />

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
              <DialogTitle>
                {cadastroSelecionado ? "Editar Cadastro" : "Novo Cadastro"}
              </DialogTitle>
              <DialogContent>
                <FormularioCadastro
                  initialValues={initialValues}
                  onCadastroSubmit={handleCadastroSubmit}
                  handleClose={handleClose}
                  formId="cadastro-form"
                />
              </DialogContent>
              <Divider />
            </Dialog>
          </MainContent>
        </ContentWrapper>
      </Root>
    </ThemeProvider>
  );
};

export default VisualizarCadastro;
