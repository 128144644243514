// src/App.js
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./componentes/DashboardCentral";
import Cadastros from "./componentes/Cadastros/Cadastros";
import LoginPage from "./componentes/LoginPage";
import VisualizarCadastroDialog from "./componentes/Cadastros/VisualizarCadastroDialog";
import CadastroDiretoria from "./componentes/Diretoria/CadastroDiretoria";
import { UserProvider, useUser } from "./UserContext";

const AppRoutes = () => {
  const { role, isAuthenticated } = useUser();

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      {isAuthenticated ? (
        <Route path="/" element={<Dashboard />}>
          <Route
            path="cadastros"
            element={
              role === "adm" ? (
                <Cadastros />
              ) : role === "operador" ? (
                <VisualizarCadastroDialog />
              ) : (
                <CadastroDiretoria />
              )
            }
          />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
    </Routes>
  );
};

const App = () => {
  return (
    <UserProvider>
      <AppRoutes />
    </UserProvider>
  );
};

export default App;
