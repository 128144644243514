import React, { createContext, useState, useContext, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );

  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    const storedIsAuthenticated = localStorage.getItem("isAuthenticated");

    console.log("Recovered role from localStorage:", storedRole);
    console.log(
      "Recovered isAuthenticated from localStorage:",
      storedIsAuthenticated
    );

    if (storedRole && storedIsAuthenticated === "true") {
      setRole(storedRole);
      setIsAuthenticated(true);
    }
  }, []);

  const login = (email, password, callback) => {
    if (email === "admin@gmail.com" && password === "admin123") {
      setRole("adm");
      setIsAuthenticated(true);
      localStorage.setItem("role", "adm");
      localStorage.setItem("isAuthenticated", "true");
      callback(true);
    } else if (email === "operador@gmail.com" && password === "operador123") {
      setRole("operador");
      setIsAuthenticated(true);
      localStorage.setItem("role", "operador");
      localStorage.setItem("isAuthenticated", "true");
      callback(true);
    } else if (email === "diretor@gmail.com" && password === "diretor123") {
      setRole("diretor");
      setIsAuthenticated(true);
      localStorage.setItem("role", "diretor");
      localStorage.setItem("isAuthenticated", "true");
      callback(true);
    } else {
      alert("Email ou senha incorretos");
      callback(false);
    }
  };

  const logout = () => {
    setRole(null);
    setIsAuthenticated(false);
    localStorage.removeItem("role");
    localStorage.removeItem("isAuthenticated");
  };

  return (
    <UserContext.Provider value={{ role, login, logout, isAuthenticated }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
