import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";

const TabelaCadastrosDiretoria = ({ cadastros, onEditar, onExcluir, role }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ações</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Código Plano</TableCell>
              <TableCell>Data Vigência</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cadastros
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((cadastro) => (
                <TableRow key={cadastro.id}>
                  <TableCell>
                    {role === "adm" ? (
                      <>
                        <IconButton onClick={() => onEditar(cadastro)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => onExcluir(cadastro.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton onClick={() => onEditar(cadastro)}>
                        <VisibilityIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>{cadastro.GRUPOEMP}</TableCell>
                  <TableCell>{cadastro.CODPLANO}</TableCell>
                  <TableCell>{cadastro.DTVIGENCIA}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 30]}
        component="div"
        count={cadastros.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TabelaCadastrosDiretoria;
