import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Grid,
  TextField,
  makeStyles,
  Divider,
  MenuItem,
  Button,
  DialogActions,
  styled,
  DialogContent,
  Tooltip,
  IconButton,
  Input,
  Toolbar,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { AttachFile } from "@material-ui/icons";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const schema = yup.object().shape({
  nome: yup.string().required("Nome é obrigatório"),
  grauParentesco: yup.string().required("Grau de Parentesco é obrigatório"),
  tipoBeneficiario: yup.string().required("Tipo de Beneficiário é obrigatório"),
  dataNascimento: yup
    .date()
    .required("Data de Nascimento é obrigatória")
    .nullable(),
  cpf: yup.string().required("CPF é obrigatório"),
  nomeMae: yup.string().required("Nome da Mãe é obrigatório"),
  estadoCivil: yup.string().required("Estado Civil é obrigatório"),
  ddd: yup.string().required("DDD é obrigatório"),
  telefone: yup.string().required("Telefone é obrigatório"),
  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  nomeSocial: yup.string().required("Nome Social é obrigatório"),
  sexo: yup.string().required("Sexo é obrigatório"),
  estado: yup.string().required("Estado é obrigatório"),
  cep: yup.string().required("CEP é obrigatório"),
  bairro: yup.string().required("Bairro é obrigatório"),
  codigoMunicipio: yup.string().required("Cód. do Município é obrigatório"),
  descricaoMunicipio: yup
    .string()
    .required("Descrição do Município é obrigatória"),
  numero: yup.string().required("Número é obrigatório"),
  tipoLogradouro: yup.string().required("Tipo de Logradouro é obrigatório"),
  logradouro: yup.string().required("Logradouro é obrigatório"),
  matricula: yup.string().required("Matrícula é obrigatória"),
  cartaoNacionalSaude: yup
    .string()
    .required("Núm. Cartão Nacional de Saúde é obrigatório"),
  dataVigencia: yup
    .date()
    .required("Data de Vigência é obrigatória")
    .nullable(),
  dataVencimento: yup
    .date()
    .required("Data de Vencimento é obrigatória")
    .nullable(),
});

const tiposBeneficiario = [
  { value: "titular", label: "Titular" },
  { value: "dependente", label: "Dependente" },
  { value: "outro", label: "Outro" },
];

const estadosCivis = [
  { value: "solteiro", label: "Solteiro(a)" },
  { value: "casado", label: "Casado(a)" },
  { value: "divorciado", label: "Divorciado(a)" },
  { value: "viuvo", label: "Viúvo(a)" },
];

const grausParentesco = [
  { value: "pai", label: "Pai" },
  { value: "mae", label: "Mãe" },
  { value: "filho", label: "Filho" },
  { value: "filha", label: "Filha" },
  { value: "avo", label: "Avô" },
  { value: "avo", label: "Avó" },
  { value: "tio", label: "Tio" },
  { value: "tia", label: "Tia" },
  { value: "primo", label: "Primo" },
  { value: "prima", label: "Prima" },
  { value: "sobrinho", label: "Sobrinho" },
  { value: "sobrinha", label: "Sobrinha" },
  { value: "padrasto", label: "Padrasto" },
  { value: "madrastra", label: "Madastra" },
  { value: "enteado", label: "Enteado" },
  { value: "enteada", label: "Enteada" },
  { value: "padrinho", label: "Padrinho" },
  { value: "madrinha", label: "Madrinha" },
];

const estados = [
  { id: "AC", nome: "Acre" },
  { id: "AL", nome: "Alagoas" },
  { id: "AP", nome: "Amapá" },
  { id: "AM", nome: "Amazonas" },
  { id: "BA", nome: "Bahia" },
  { id: "CE", nome: "Ceará" },
  { id: "DF", nome: "Distrito Federal" },
  { id: "ES", nome: "Espírito Santo" },
  { id: "GO", nome: "Goiás" },
  { id: "MA", nome: "Maranhão" },
  { id: "MT", nome: "Mato Grosso" },
  { id: "MS", nome: "Mato Grosso do Sul" },
  { id: "MG", nome: "Minas Gerais" },
  { id: "PA", nome: "Pará" },
  { id: "PB", nome: "Paraíba" },
  { id: "PR", nome: "Paraná" },
  { id: "PE", nome: "Pernambuco" },
  { id: "PI", nome: "Piauí" },
  { id: "RJ", nome: "Rio de Janeiro" },
  { id: "RN", nome: "Rio Grande do Norte" },
  { id: "RS", nome: "Rio Grande do Sul" },
  { id: "RO", nome: "Rondônia" },
  { id: "RR", nome: "Roraima" },
  { id: "SC", nome: "Santa Catarina" },
  { id: "SP", nome: "São Paulo" },
  { id: "SE", nome: "Sergipe" },
  { id: "TO", nome: "Tocantins" },
];

const orgaosEmissores = [
  { value: "ssp", label: "SSP" },
  { value: "detran", label: "DETRAN" },
  { value: "pf", label: "Polícia Federal" },
  { value: "outro", label: "Outro" },
];

const sexos = [
  { id: "M", nome: "Masculino" },
  { id: "F", nome: "Feminino" },
  { id: "O", nome: "Outro" },
];

const tiposLogradouro = [
  { value: "rua", label: "Rua" },
  { value: "avenida", label: "Avenida" },
  { value: "travessa", label: "Travessa" },
  { value: "praca", label: "Praça" },
  { value: "outro", label: "Outro" },
];

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    fontFamily: "Montserrat, sans-serif",
  },
  textField: {
    marginBottom: theme.spacing(1),
    fontFamily: "Montserrat, sans-serif",
  },
  submitButton: {
    marginTop: theme.spacing(1),
    fontFamily: "Montserrat, sans-serif",
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: "#05bcbc",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#74d9d9",
  },
}));

const FormularioCadastro = ({
  initialValues,
  onCadastroSubmit,
  formId,
  handleClose,
}) => {
  const classes = useStyles();

  const { control, watch, reset, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (initialValues) {
      Object.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
        console.log(`SetValue: ${key} - ${initialValues[key]}`);
      });
    }
  }, [initialValues, setValue]);

  const onSubmit = (data) => {
    console.log("Dados do formulário:", data);
    onCadastroSubmit(data);
  };

  return (
    <form
      className={classes.form}
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="nome"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Nome"
                fullWidth
                variant="outlined"
                required
                error={!!errors.nome}
                helperText={errors?.nome?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="grauParentesco"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                select
                label="Grau Parentesco"
                fullWidth
                variant="outlined"
                required
                error={!!errors.grauParentesco}
                helperText={errors?.grauParentesco?.message}
              >
                {grausParentesco.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="tipoBeneficiario"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                select
                label="Tipo de Beneficiário"
                fullWidth
                variant="outlined"
                required
                error={!!errors.tipoBeneficiario}
                helperText={errors?.tipoBeneficiario?.message}
              >
                {tiposBeneficiario.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="dataNascimento"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputMask {...field} mask="99/99/9999" maskChar="">
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    className={classes.textField}
                    label="Data de Nascimento"
                    fullWidth
                    variant="outlined"
                    required
                    error={!!errors.dataNascimento}
                    helperText={errors?.dataNascimento?.message}
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="cpf"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="CPF"
                fullWidth
                variant="outlined"
                required
                error={!!errors.cpf}
                helperText={errors?.cpf?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="rg"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="RG"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="orgaoEmissor"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                select
                label="Órgão Emissor"
                fullWidth
                variant="outlined"
              >
                {orgaosEmissores.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="dataExpedicao"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputMask {...field} mask="99/99/9999" maskChar="">
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    className={classes.textField}
                    label="Data Expedição"
                    fullWidth
                    variant="outlined"
                    required
                    error={!!errors.dataExpedicao}
                    helperText={errors?.dataExpedicao?.message}
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>

        <Grid item xs={8}>
          <Controller
            name="numeroPis"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Número Pis"
                fullWidth
                variant="outlined"
                required
                error={!!errors.numeroPis}
                helperText={errors?.numeroPis?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={8}>
          <Controller
            name="nomeMae"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Nome da Mãe"
                fullWidth
                variant="outlined"
                required
                error={!!errors.nomeMae}
                helperText={errors?.nomeMae?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="estadoCivil"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                select
                label="Estado Civil"
                fullWidth
                variant="outlined"
                required
                error={!!errors.estadoCivil}
                helperText={errors?.estadoCivil?.message}
              >
                {estadosCivis.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={1}>
          <Controller
            name="ddd"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="DDD"
                fullWidth
                variant="outlined"
                required
                error={!!errors.ddd}
                helperText={errors?.ddd?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="telefone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Telefone"
                fullWidth
                variant="outlined"
                required
                error={!!errors.telefone}
                helperText={errors?.telefone?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={8}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="E-mail"
                fullWidth
                variant="outlined"
                required
                error={!!errors.email}
                helperText={errors?.email?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="nomeSocial"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Nome Social"
                fullWidth
                variant="outlined"
                required
                error={!!errors.nomeSocial}
                helperText={errors?.nomeSocial?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="nomePai"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Nome do Pai"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Controller
            name="sexo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Sexo"
                fullWidth
                variant="outlined"
                required
                error={!!errors.sexo}
                helperText={errors?.sexo?.message}
              >
                {sexos.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.nome}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="estado"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                select
                label="Estado"
                fullWidth
                variant="outlined"
                required
                error={!!errors.estado}
                helperText={errors?.estado?.message}
              >
                {estados.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.nome}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="cep"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="CEP"
                fullWidth
                variant="outlined"
                required
                error={!!errors.cep}
                helperText={errors?.cep?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="bairro"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Bairro"
                fullWidth
                variant="outlined"
                required
                error={!!errors.bairro}
                helperText={errors?.bairro?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Controller
            name="complemento"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Complemento"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="municipio"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Município"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="codigoMunicipio"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Cód. do Município"
                fullWidth
                variant="outlined"
                required
                error={!!errors.codigoMunicipio}
                helperText={errors?.codigoMunicipio?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="descricaoMunicipio"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Descrição do Município"
                fullWidth
                variant="outlined"
                required
                error={!!errors.descricaoMunicipio}
                helperText={errors?.descricaoMunicipio?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Controller
            name="numero"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Número"
                fullWidth
                variant="outlined"
                required
                error={!!errors.numero}
                helperText={errors?.numero?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="tipoLogradouro"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                select
                label="Tipo de Logradouro"
                fullWidth
                variant="outlined"
                required
                error={!!errors.tipoLogradouro}
                helperText={errors?.tipoLogradouro?.message}
              >
                {tiposLogradouro.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="logradouro"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Logradouro"
                fullWidth
                variant="outlined"
                required
                error={!!errors.logradouro}
                helperText={errors?.logradouro?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="codigoPlano"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Código do Plano"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="matricula"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Matrícula"
                fullWidth
                variant="outlined"
                required
                error={!!errors.matricula}
                helperText={errors?.matricula?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="declaracaoNascidoVivo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Declaração de Nascido Vivo"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="cartaoNacionalSaude"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Núm. Cartão Nacional de Saúde"
                fullWidth
                variant="outlined"
                required
                error={!!errors.cartaoNacionalSaude}
                helperText={errors?.cartaoNacionalSaude?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="dataVigencia"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputMask {...field} mask="99/99/9999" maskChar="">
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    className={classes.textField}
                    label="Data de Vigência"
                    fullWidth
                    variant="outlined"
                    required
                    error={!!errors.dataVigencia}
                    helperText={errors?.dataVigencia?.message}
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="dataVencimento"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputMask {...field} mask="99/99/9999" maskChar="">
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    className={classes.textField}
                    label="Data de Vencimento"
                    fullWidth
                    variant="outlined"
                    required
                    error={!!errors.dataVencimento}
                    helperText={errors?.dataVencimento?.message}
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="dataCasamento"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputMask {...field} mask="99/99/9999" maskChar="">
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    className={classes.textField}
                    label="Data de Casamento"
                    fullWidth
                    variant="outlined"
                    error={!!errors.dataCasamento}
                    helperText={errors?.dataCasamento?.message}
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="codigoBanco"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Código Banco"
                fullWidth
                variant="outlined"
                error={!!errors.codigoBanco}
                helperText={errors?.codigoBanco?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="digitoAgencia"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Digito Agência"
                fullWidth
                variant="outlined"
                error={!!errors.digitoAgencia}
                helperText={errors?.digitoAgencia?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="numeroConta"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Número Conta"
                fullWidth
                variant="outlined"
                error={!!errors.numeroConta}
                helperText={errors?.numeroConta?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="digitoConta"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Dgito Conta"
                fullWidth
                variant="outlined"
                error={!!errors.digitoConta}
                helperText={errors?.digitoConta?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="pesoBeneficiario"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Peso"
                fullWidth
                variant="outlined"
                error={!!errors.pesoBeneficiario}
                helperText={errors?.pesoBeneficiario?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="alturaBeneficiario"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.textField}
                label="Altura"
                fullWidth
                variant="outlined"
                error={!!errors.alturaBeneficiario}
                helperText={errors?.alturaBeneficiario?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Input
            type="file"
            id="fileMarriage"
            style={{ display: "none" }}
            inputProps={{ "aria-label": "Upload certidão de casamento" }}
          />
          <Tooltip title="Certidão de Casamento">
            <IconButton
              color="primary"
              component="label"
              htmlFor="fileMarriage"
              style={{ marginRight: 8 }} // Adiciona uma margem à direita
            >
              <AttachFileIcon />
            </IconButton>
          </Tooltip>

          <Input
            type="file"
            id="fileBirth"
            style={{ display: "none" }}
            inputProps={{ "aria-label": "Upload certidão de nascimento" }}
          />
          <Tooltip title="Certidão de Nascimento">
            <IconButton color="primary" component="label" htmlFor="fileBirth">
              <CloudUploadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Divider />
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button color="primary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          //disabled={_.isEmpty(dirtyFields) || !isValid}
        >
          {initialValues ? "Salvar" : "Cadastrar"}
        </Button>
      </DialogActions>
    </form>
  );
};

export default FormularioCadastro;
