import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#eef7f7",
    color: "#282128",
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    fontFamily: "Montserrat, sans-serif",
    width: "95%",
  },
  avatar: {
    backgroundColor: "#09a1b7",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  divider: {
    backgroundColor: "#282128",
    margin: `${theme.spacing(2)}px 0`,
  },
  button: {
    backgroundColor: "#72d4d5",
    color: "#282128",
    "&:hover": {
      backgroundColor: "#09a1b7",
    },
    padding: theme.spacing(1),
    display: "block",
    margin: "0 auto",
    width: "100%",
    fontFamily: "Montserrat, sans-serif",
  },
  typography: {
    color: "#282128",
    marginLeft: theme.spacing(1),
    fontFamily: "Montserrat, sans-serif",
  },
  content: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
}));

const CadastrosSidebarContent = ({ onAddClick }) => {
  const classes = useStyles();

  return (
    <div className="p-0 lg:p-24 lg:ltr:pr-4 lg:rtl:pl-4">
      <Paper
        component={motion.div}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
        className={`rounded-0 shadow-none lg:rounded-16 lg:shadow ${classes.paper}`}
      >
        <div className={classes.content}>
          <Avatar
            alt="Administrador"
            src="assets/images/avatars/profile.jpg"
            className={classes.avatar}
          />
          <Typography className={classes.typography}>Administrador</Typography>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.content}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={onAddClick}
          >
            Adicionar
           
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default CadastrosSidebarContent;
