import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  CssBaseline,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useUser } from "../UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#c7dee6",
    fontFamily: "Montserrat, sans-serif",
  },
  container: {
    backgroundColor: "#ffffff", // Fundo branco
    borderRadius: theme.spacing(2),
    padding: theme.spacing(6),
    boxShadow: theme.shadows[6], // Sombra mais pronunciada
    [theme.breakpoints.down("sm")]: {
      boxShadow: theme.shadows[3], // Sombra menos pronunciada em telas menores
      padding: theme.spacing(4),
    },
  },
  gridContainer: {
    height: "100%",
  },
  welcomeText: {
    color: "#09a1b7", // Azul para o texto de boas-vindas
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    color: "#686464", // Cinza para o subtítulo
    textAlign: "center",
  },
  textField: {
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(3),
    backgroundColor: "#09a1b7", // Azul para o botão
    color: "#ffffff", // Texto branco
    "&:hover": {
      backgroundColor: "#07202b", // Azul mais escuro ao passar o mouse
    },
  },
  logo: {
    marginBottom: theme.spacing(1),
    width: "200px", // Ajuste o tamanho do logotipo conforme necessário
  },
  secondaryLogo: {
    marginTop: theme.spacing(1), // Espaçamento entre os logotipos
    width: "150px", // Ajuste o tamanho do segundo logotipo conforme necessário
  },
  leftSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useUser();
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    login(email, password, (success) => {
      if (success) {
        navigate('/');
      }
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={4} className={classes.gridContainer}>
          {/* Lado esquerdo: Logotipo e texto de boas-vindas */}
          <Grid item xs={12} sm={6} className={classes.leftSide}>
            <img
              src="/logoNascare.png" // Coloque o caminho do segundo logotipo aqui
              alt="Segundo Logotipo"
              className={classes.secondaryLogo}
            />
            <img
              src="/logoOesteSaude.png" // Coloque o caminho do logotipo aqui
              alt="Oeste Saúde"
              className={classes.logo}
            />
            <Typography variant="h4" className={classes.welcomeText}>
              Bem-vindo de volta!
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Faça login para continuar acessando nossos serviços.
            </Typography>
          </Grid>
          {/* Lado direito: Formulário de Login */}
          <Grid item xs={12} sm={6}>
            <form className={classes.form}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                className={classes.textField}
                autoFocus
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                id="password"
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                className={classes.textField}
                required
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                className={classes.submitButton}
                onClick={handleLogin}
              >
                Entrar
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
